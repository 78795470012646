<template>
  <div>
    <custom-header></custom-header>
    <div class="container">
      <div class="my-form">
        <p class="title">重置密码</p>
        <el-form
          ref="loginForm"
          :model="loginForm"
          :rules="loginRules"
          class="login-form"
          auto-complete="new-password"
          label-width="110px"
        >
          <el-form-item prop="mobile" label="手机号">
            <el-input
              v-model="loginForm.mobile"
              name="mobile"
              type="text"
              maxlength="11"
              auto-complete="new-password"
              placeholder="请输入手机号"
            />
          </el-form-item>
          <!-- <el-form-item prop="mobile" label="图形验证码">
            <el-input
              style="width: 76%; margin-right: 10px"
              :type="passwordType"
              v-model="loginForm.password"
              name="password"
         auto-complete="new-password"
              placeholder="请输入图形验证码"
              @keyup.enter.native="handleNext"
            />
            <el-button
              :type="codeShow ? 'primary' : 'info'"
              @click="sendCode"
              :disabled="smsCodeDisable"
            >
              <span v-show="codeShow">获取验证码</span>
              <span v-show="!codeShow"> {{ snsMsgWait }}</span>
            </el-button>
          </el-form-item> -->
          <el-form-item prop="verifyCode" label="验证码">
            <div class="row">
              <el-input
                :type="passwordType"
                v-model="loginForm.verifyCode"
                name="verifyCode"
                auto-complete="new-password"
                placeholder="请输入验证码"
              />
              <el-button
                @click="sendCode"
                :disabled="smsFlag"
                :style="{
                  fontSize: '14px',
                  backgroundColor: sendColor,
                  borderColor: sendColor,
                  color: '#fff',
                }"
              >
                {{ sendTime }}
              </el-button>
            </div>
          </el-form-item>
          <el-form-item prop="newPassword" label="新密码">
            <el-input
              v-model="loginForm.newPassword"
              name="newPassword"
              type="text"
              auto-complete="new-password"
              placeholder="请输入新密码"
            />
          </el-form-item>
          <el-form-item prop="passwdCheck" label="确认新密码">
            <el-input
              v-model="loginForm.passwdCheck"
              name="passwdCheck"
              type="text"
              auto-complete="new-password"
              placeholder="请输入确认新密码"
            />
          </el-form-item>
          <div class="small-btn">
            <el-button
              :loading="loading"
              type="primary"
              @click.native.prevent="handleSubmit('loginForm')"
              >保存
            </el-button>
          </div>
        </el-form>
      </div>
    </div>
    <custom-footer></custom-footer>
  </div>
</template>

<script>
import { sendSms, forgetPassword } from "@/api/index";
export default {
  name: "login",
  data() {
    const validatePhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else if (!/^1[0-9]{10}$/.test(value)) {
        callback("请填写正确的手机号");
      } else {
        callback();
      }
    };
    const validatePass = (rule, value, callback) => {
      let reg = /^[^\s]{6,10}$/;
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (!reg.test(value)) {
        callback(new Error("密码6-10位，不包含空格"));
      } else {
        callback();
      }
    };
    const validatePassCheck = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.loginForm.newPassword) {
        callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    };
    return {
      sendTime: "获取验证码",
      sendColor: "#CAA774",
      snsMsgWait: 60,
      smsFlag: false,
      loginForm: {
        mobile: "",
        type: 3,
        newPassword: "",
        verifyCode: "",
        passwdCheck: "",
      },
      loginRules: {
        mobile: [
          {
            required: true,
            validator: validatePhone,
            trigger: "blur",
          },
        ],
        verifyCode: [
          { required: true, message: "验证码不允许为空", trigger: "blur" },
        ],
        newPassword: [
          {
            required: true,
            message: "密码不能为空",
            trigger: "blur",
          },
          {
            validator: validatePass,
            trigger: "blur",
          },
        ],
        passwdCheck: [
          {
            required: true,
            validator: validatePassCheck,
            trigger: "blur",
          },
        ],
      },
      passwordType: "password",
      loading: false,
    };
  },

  methods: {
    // 获取验证码
    sendCode() {
      if (!/^1\d{10}$/.test(this.loginForm.mobile)) {
        this.$message.info("请填写正确的手机号");
        return;
      }
      var params = {
        mobile: this.loginForm.mobile,
        type: 3,
      };
      sendSms(params).then(() => {
        this.$message.success("短信发送成功");
        this.countDown();
      });
    },
    countDown() {
      // 60秒后重新获取验证码
      var inter = setInterval(
        function () {
          this.smsFlag = true;
          this.sendColor = "#E0E0E0";
          this.sendTime = this.snsMsgWait + "s后可重发";
          this.snsMsgWait = this.snsMsgWait - 1;
          if (this.snsMsgWait < 0) {
            clearInterval(inter);
            this.smsFlag = false;
            this.sendColor = "#CAA774";
            this.sendTime = "获取验证码";
            this.snsMsgWait = 60;
          }
        }.bind(this),
        1000
      );
    },
    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
    },

    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          forgetPassword(this.loginForm)
            .then((res) => {
              console.log(res);
              this.$message.success({message: '修改成功，请重新登录',duration: 2000})
              setTimeout(() =>{
                this.$router.push({
                  name: 'login'
                })
              },2000)
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.container {
  width: 1200px;
  margin: 0 auto 30px;
  background: #fff;
  padding: 100px 0 200px 0;
}
.my-form {
  width: 55%;
  margin: 0 auto;
  .title {
    font-size: 24px;
    color: #020202;
    margin: 30px auto;
    text-align: center;
  }
}
</style>

